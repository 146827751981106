import React from "react";
import { styleCustom } from "../utils/styleCustom";

export default function ButtonCustom({
  text,
  color = "primary",
  className,
  ...props
}) {
  const classCustom = styleCustom(color);

  return (
    <button
      className={"rounded-full py-2 px-4 font-bold " + classCustom + className}
      {...props}
    >
      {text}
    </button>
  );
}
