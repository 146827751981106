import React from "react";
import { useNavigate, useParams } from "react-router-dom";
import {
  getUsers,
  getEquipment,
  postEquipment,
  putEquipment,
} from "../../../services/ws";

import Swal from "sweetalert2";
import ContentHeader from "../../../components/ContentHeader";
import { ButtonCustomRef } from "../../../components/ButtonCustomRef";
import {
  ContainerInput,
  InputCustom,
  SelectCustom,
} from "../../../components/FormElements";

import { lstTypeEquipments, lstBrands } from "../../../utils/LstData";

export default function EquipmentForm() {
  const { id } = useParams();

  const btn = React.useRef();
  const navigate = useNavigate();

  const [current, setCurrent] = React.useState({});
  const [lstUsers, setLstUsers] = React.useState([]);

  React.useEffect(() => {
    handleGet();
  }, []);

  React.useEffect(() => {
    if (id) {
      getDataCurrent(id);
    }
  }, [id]);

  const getDataCurrent = async (idCurrent) => {
    const resp = await getEquipment(idCurrent);
    if (resp.success) {
      setCurrent(resp.data);
    }
  };

  const handleGet = async () => {
    const resp = await getUsers();
    if (resp.success) {
      setLstUsers(resp.data);
    }
  };

  const handleChange = (e) => {
    setCurrent({ ...current, [e.target.name]: e.target.value });
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    console.log("current", current);
    btn && btn.current.setAttribute("disabled", true);

    try {
      if (id) {
        const resp = await putEquipment(current);
        if (resp.success) {
          Swal.fire({
            icon: "success",
            title: "Se ha actualizado correctamente",
            text: "",
          });
        }
      } else {
        const resp = await postEquipment(current);
        if (resp.success) {
          Swal.fire({
            icon: "success",
            title: "Se ha registrado correctamente",
            text: "",
          });
        }
      }
      navigate("/equipments");
    } catch (error) {
      Swal.fire({
        icon: "error",
        title: "Oops...",
        text: "Algo salió mal!",
      });
      btn && btn.current.removeAttribute("disabled");
    }
  };

  return (
    <div className="flex flex-col">
      <ContentHeader title={id ? "Editar Equipo" : "Registrar Equipo"}>
        <ButtonCustomRef
          text="Guardar"
          ref={btn}
          form="form-current"
          type="submit"
        />
      </ContentHeader>
      <div className="flex justify-center py-8">
        <form
          id="form-current"
          className="w-full max-w-3xl rounded-lg border border-blue-200 px-8 py-10"
          onSubmit={handleSubmit}
        >
          <ContainerInput>
            <SelectCustom
              text="Cliente"
              name="user_id"
              value={current.user_id}
              onChange={handleChange}
              lst={lstUsers}
              required
            />
          </ContainerInput>

          <ContainerInput>
            <InputCustom
              text="Serie"
              name="serie"
              value={current.serie}
              n="2"
              onChange={handleChange}
              required
            />
            <SelectCustom
              text="Tipo"
              name="type"
              value={current.type}
              n="2"
              onChange={handleChange}
              lst={lstTypeEquipments}
              required
            />
          </ContainerInput>

          <ContainerInput>
            <SelectCustom
              text="Marca"
              name="brand"
              value={current.brand}
              n="2"
              onChange={handleChange}
              lst={lstBrands}
              required
            />
            <InputCustom
              text="Modelo"
              name="model"
              value={current.model}
              n="2"
              onChange={handleChange}
              required
            />
          </ContainerInput>
        </form>
      </div>
    </div>
  );
}
