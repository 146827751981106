import React from "react";
import { useNavigate, useParams } from "react-router-dom";
import { getRoles, getUser, postUser, putUser } from "../../../services/ws";

import Swal from "sweetalert2";
import ContentHeader from "../../../components/ContentHeader";
import { ButtonCustomRef } from "../../../components/ButtonCustomRef";
import {
  ContainerInput,
  InputCustom,
  SelectCustom,
} from "../../../components/FormElements";

export default function UserForm() {
  const { id } = useParams();

  const btn = React.useRef();
  const navigate = useNavigate();

  const [user, setUser] = React.useState({});
  const [lstRole, setLstRole] = React.useState([]);

  React.useEffect(() => {
    getDataRoles();
  }, []);

  React.useEffect(() => {
    if (id) {
      getDataUser(id);
    }
  }, [id]);

  const getDataUser = async (idUser) => {
    const resp = await getUser(idUser);
    if (resp.success) {
      setUser(resp.data);
    }
  };

  const getDataRoles = async () => {
    const resp = await getRoles();
    if (resp.success) {
      setLstRole(resp.data);
    }
  };

  const handleChange = (e) => {
    setUser({ ...user, [e.target.name]: e.target.value });
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    btn && btn.current.setAttribute("disabled", true);

    try {
      if (id) {
        const resp = await putUser(user);
        if (resp.success) {
          Swal.fire({
            icon: "success",
            title: "Se ha actualizado correctamente",
            text: "",
          });
          navigate("/users");
        } else {
          Swal.fire({
            icon: "error",
            title: "Validación",
            text: resp.message,
          });
        }
      } else {
        const resp = await postUser(user);
        if (resp.success) {
          Swal.fire({
            icon: "success",
            title: "Se ha registrado correctamente",
            text: "",
          });
          navigate("/users");
        } else {
          Swal.fire({
            icon: "error",
            title: "Validación",
            text: resp.message,
          });
        }
      }
    } catch (error) {
      Swal.fire({
        icon: "error",
        title: "Oops...",
        text: "Algo salió mal!",
      });
      btn && btn.current.removeAttribute("disabled");
    }
  };

  return (
    <div className="flex flex-col">
      <ContentHeader title={id ? "Editar Usuario" : "Registrar Usuario"}>
        <ButtonCustomRef
          text="Guardar"
          ref={btn}
          form="form-user"
          type="submit"
        />
      </ContentHeader>
      <div className="flex justify-center py-8">
        <form
          id="form-user"
          className="w-full max-w-3xl rounded-lg border border-blue-200 px-8 py-10"
          onSubmit={handleSubmit}
        >
          <ContainerInput>
            <InputCustom
              text="Nombre"
              name="name"
              value={user.name}
              n="2"
              onChange={handleChange}
              required
            />
            <InputCustom
              text="Apellido"
              name="last_name"
              value={user.last_name}
              n="2"
              onChange={handleChange}
            />
          </ContainerInput>
          <ContainerInput>
            <InputCustom
              text="Cédula"
              name="ci"
              value={user.ci}
              n="2"
              onChange={handleChange}
              minLength="10"
              maxLength="10"
              required
            />
            <InputCustom
              text="Teléfono"
              name="phone"
              value={user.phone}
              n="2"
              onChange={handleChange}
            />
          </ContainerInput>

          <ContainerInput>
            <InputCustom
              text="Email"
              name="email"
              value={user.email}
              type="email"
              onChange={handleChange}
              required
            />
          </ContainerInput>
          <ContainerInput>
            <InputCustom
              text="Dirección"
              name="address"
              value={user.address}
              onChange={handleChange}
            />
          </ContainerInput>
          <ContainerInput>
            <SelectCustom
              text="Rol"
              name="role_id"
              value={user.role_id}
              onChange={handleChange}
              lst={lstRole}
              required
            />
          </ContainerInput>

          <ContainerInput>
            <InputCustom
              text="Contraseña"
              name="password"
              value={user.password}
              type="password"
              onChange={handleChange}
              minLength="8"
            />
          </ContainerInput>
        </form>
      </div>
    </div>
  );
}
