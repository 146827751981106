import React from "react";
import TableCustom from "../../../components/TableCustom";
import { getServices, deleteService } from "../../../services/ws";

import Swal from "sweetalert2";
import ContentHeader from "../../../components/ContentHeader";
import LinkCustom from "../../../components/LinkCustom";
import ButtonCustom from "../../../components/ButtonCustom";
import Loading from "../../../components/Loading";

export default function ServicesList() {
  const columns = [
    {
      Header: "ID",
      accessor: "id",
    },
    {
      Header: "Fecha de ingeso",
      accessor: "created_at",
    },
    {
      Header: "Serie de equipo",
      accessor: "equipment.serie",
    },
    {
      Header: "Cliente",
      accessor: "client.name",
    },
    {
      Header: "Accesorios",
      accessor: "accessories",
    },
    {
      Header: "Falla",
      accessor: "failure",
    },
    {
      Header: "Responsable",
      accessor: "responsible.name",
    },
    {
      Header: "Fecha de entrega",
      accessor: "delivery_date",
    },
    {
      Header: "Opciones",
      accessor: (props) => (
        <div className="flex flex-col items-center justify-center">
          <LinkCustom
            text="Editar"
            to={`/services/${props.id}`}
            className="text-sm"
          />
          <LinkCustom
            text="Informe"
            to={`/services/${props.id}`}
            className="text-sm"
          />
          <ButtonCustom
            text="Eliminar"
            onClick={() => handleDelete(props.id)}
            className="text-sm"
            color="danger"
          />
          `
        </div>
      ),
    },
  ];

  const [isLoading, setIsLoading] = React.useState(true);

  const [data, setData] = React.useState([]);

  React.useEffect(() => {
    handleGet();
  }, []);

  const handleGet = async () => {
    const resp = await getServices();
    if (resp.success) {
      setData(resp.data);
    }
    setIsLoading(false);
  };

  const handleDelete = async (id) => {
    Swal.fire({
      title: "¿Estás seguro?",
      text: "Una vez eliminado, no podrás recuperar este registro!",
      icon: "warning",
      showCancelButton: true,
      confirmButtonColor: "#3085d6",
      cancelButtonColor: "#d33",
      confirmButtonText: "Si, eliminar!",
    }).then(async (result) => {
      if (result.value) {
        const resp = await deleteService(id);
        if (resp.success) {
          Swal.fire("Eliminado!", resp.message, "success");
          handleGet();
        }
      }
    });
  };

  if (isLoading) {
    return <Loading />;
  }

  return (
    <>
      <ContentHeader
        to={`/services/new`}
        title="Listado de usuarios"
        text="Ingresar Orden"
      />
      <TableCustom columns={columns} data={data} />
    </>
  );
}
