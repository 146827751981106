import React from "react";
import { useNavigate, useParams } from "react-router-dom";
import {
  getService,
  getUsers,
  getUserByCI,
  postService,
  putService,
  getEquipmentBySerie,
} from "../../../services/ws";

import Swal from "sweetalert2";
import ContentHeader from "../../../components/ContentHeader";
import { ButtonCustomRef } from "../../../components/ButtonCustomRef";
import {
  ContainerInput,
  InputCustom,
  SelectCustom,
} from "../../../components/FormElements";

import { lstTypeEquipments, lstBrands } from "../../../utils/LstData";

export default function ServiceForm() {
  const { id } = useParams();

  const btn = React.useRef();
  const navigate = useNavigate();

  const [current, setCurrent] = React.useState({});
  const [lstUsers, setLstUsers] = React.useState([]);

  const [user, setUser] = React.useState({});
  const [equipment, setEquipment] = React.useState({});

  React.useEffect(() => {
    handleGet();
  }, []);

  React.useEffect(() => {
    if (id) {
      getDataCurrent(id);
    }
  }, [id]);

  /* React.useEffect(() => {
    if (current.client_id) {
      setUser(lstUsers.find((item) => item.id === parseInt(current.client_id)));
    } else {
      setUser({});
    }
  }, [lstUsers, current]); */

  const getDataCurrent = async (idCurrent) => {
    const resp = await getService(idCurrent);
    if (resp.success) {
      setCurrent(resp.data);
    }
  };

  const handleGet = async () => {
    const resp = await getUsers();
    if (resp.success) {
      setLstUsers(resp.data);
    }
  };

  const handleChange = (e) => {
    setCurrent({ ...current, [e.target.name]: e.target.value });
  };

  const handleChangeUser = (e) => {
    setUser({ ...user, [e.target.name]: e.target.value });
  };

  const handleChangeEquipment = (e) => {
    setEquipment({ ...equipment, [e.target.name]: e.target.value });
  };

  const handleGetUser = async (e) => {
    const resp = await getUserByCI(e.target.value);
    if (resp.success) {
      if (resp.data.id) {
        setUser(resp.data);
      }
    } else {
      setUser({});
    }
  };

  const handleGetEquipment = async (e) => {
    const resp = await getEquipmentBySerie({
      serie: e.target.value,
    });
    if (resp.success) {
      if (resp.data.id) {
        setEquipment(resp.data);
      }
    } else {
      setEquipment({});
    }
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    btn && btn.current.setAttribute("disabled", true);
    console.log("submit");

    try {
      const json = current;
      json.client = user;
      json.equipment = equipment;

      if (id) {
        const resp = await putService(current);
        if (resp.success) {
          Swal.fire({
            icon: "success",
            title: "Se ha actualizado correctamente",
            text: "",
          });
        }
      } else {
        console.log("current", current);
        const resp = await postService(current);
        if (resp.success) {
          Swal.fire({
            icon: "success",
            title: "Se ha registrado correctamente",
            text: "",
          });
        }
      }
      navigate("/services");
    } catch (error) {
      Swal.fire({
        icon: "error",
        title: "Oops...",
        text: "Algo salió mal!",
      });
      btn && btn.current.removeAttribute("disabled");
    }
  };

  return (
    <div className="flex flex-col">
      <ContentHeader title={id ? "Editar Servicio" : "Registrar Servicio"}>
        <ButtonCustomRef
          text="Guardar"
          ref={btn}
          form="form-current"
          type="submit"
        />
      </ContentHeader>
      <div className="flex justify-center py-8">
        <form
          id="form-current"
          className="w-full max-w-3xl rounded-lg border border-blue-200 px-8 py-10"
          onSubmit={handleSubmit}
        >
          <ContainerInput>
            <InputCustom
              text="Cédula"
              name="ci"
              value={current.ci}
              n="2"
              onChange={handleChange}
              onKeyUp={handleGetUser}
              required
            />
          </ContainerInput>

          <ContainerInput>
            <InputCustom
              text="Nombre"
              name="name"
              value={user.name}
              n="2"
              onChange={handleChangeUser}
              required
            />
            <InputCustom
              text="Apellido"
              name="last_name"
              value={user.last_name}
              n="2"
              onChange={handleChangeUser}
              required
            />
          </ContainerInput>
          <ContainerInput>
            <InputCustom
              text="Teléfono"
              name="phone"
              value={user.phone}
              n="2"
              onChange={handleChangeUser}
            />
            <InputCustom
              text="Email"
              name="email"
              value={user.email}
              n="2"
              type="email"
              onChange={handleChangeUser}
            />
          </ContainerInput>

          <ContainerInput>
            <InputCustom
              text="Dirección"
              name="address"
              value={user.address}
              onChange={handleChangeUser}
            />
          </ContainerInput>

          <div className="mt-8 flex flex-col p-3">
            <h2 className="text-xl font-bold text-gray-700">
              Datos del equipo
            </h2>
          </div>

          <ContainerInput>
            <InputCustom
              text="Serie"
              name="serie"
              value={current.serie}
              n="2"
              onChange={handleChange}
              onKeyUp={handleGetEquipment}
              required
            />
            <SelectCustom
              text="Tipo"
              name="type"
              value={equipment.type}
              n="2"
              onChange={handleChangeEquipment}
              lst={lstTypeEquipments}
              required
            />
          </ContainerInput>

          <ContainerInput>
            <SelectCustom
              text="Marca"
              name="brand"
              value={equipment.brand}
              n="2"
              onChange={handleChangeEquipment}
              lst={lstBrands}
              required
            />
            <InputCustom
              text="Modelo"
              name="model"
              value={equipment.model}
              n="2"
              onChange={handleChangeEquipment}
              required
            />
          </ContainerInput>

          <div className="mt-8 flex flex-col p-3">
            <h2 className="text-xl font-bold text-gray-700">
              Información de adicional
            </h2>
          </div>

          <ContainerInput>
            <InputCustom
              text="Accesorios"
              name="accessories"
              value={current.accessories}
              onChange={handleChange}
            />
          </ContainerInput>

          <ContainerInput>
            <InputCustom
              text="Falla reportada"
              name="failure"
              value={current.failure}
              onChange={handleChange}
            />
          </ContainerInput>

          <div className="mt-8 flex flex-col p-3">
            <h2 className="text-xl font-bold text-gray-700">Responsable</h2>
          </div>

          <ContainerInput>
            <SelectCustom
              text="Responsable"
              name="responsible_id"
              value={current.responsible_id}
              onChange={handleChange}
              lst={lstUsers}
              required
            />
          </ContainerInput>

          <ContainerInput>
            <InputCustom
              text="Fecha de entrega"
              name="delivery_date"
              value={
                current.delivery_date
                  ? current.delivery_date
                  : new Date().toISOString().substr(0, 10)
              }
              onChange={handleChange}
              type="date"
              required
            />
          </ContainerInput>
        </form>
      </div>
    </div>
  );
}
