export const lstTypeMaterials = [
  {
    id: 1,
    name: "Material",
  },
  {
    id: 2,
    name: "Insumo",
  },
  {
    id: 3,
    name: "Repuesto",
  },
];

export const lstTypeEquipments = [
  {
    id: 1,
    name: "Laptop",
  },
  {
    id: 2,
    name: "PC",
  },
  {
    id: 3,
    name: "Celular",
  },
  {
    id: 4,
    name: "Tablet",
  },
];

export const lstBrands = [
  {
    id: 1,
    name: "Samsung",
  },
  {
    id: 2,
    name: "Apple",
  },
  {
    id: 3,
    name: "LG",
  },
  {
    id: 4,
    name: "Sony",
  },
  {
    id: 5,
    name: "Xiaomi",
  },
  {
    id: 6,
    name: "Huawei",
  },
];

export const lstStatesService = [
  {
    id: 1,
    name: "Sin Revisar",
  },
  {
    id: 2,
    name: "Trabajo terminado",
  },
  {
    id: 3,
    name: "En Reparación",
  },
  {
    id: 4,
    name: "Sin solución",
  },
];
