import {
  FastArrowLeft,
  FastArrowRight,
  NavArrowLeft,
  NavArrowRight,
} from "iconoir-react";
import React from "react";
import {
  useTable,
  useAsyncDebounce,
  useGlobalFilter,
  usePagination,
} from "react-table";
import ButtonNavigation from "./ButtonNavigation";

function GlobalFilter({
  preGlobalFilteredRows,
  globalFilter,
  setGlobalFilter,
}) {
  const count = preGlobalFilteredRows.length;
  const [value, setValue] = React.useState(globalFilter);
  const onChange = useAsyncDebounce((value) => {
    setGlobalFilter(value || undefined);
  }, 200);

  return (
    <div className="my-5 flex items-center justify-center">
      <span className="pr-2 text-sm text-gray-600">Buscar:</span>
      <input
        className="rounded-lg border border-blue-600 py-1 px-2"
        value={value || ""}
        onChange={(e) => {
          setValue(e.target.value);
          onChange(e.target.value);
        }}
        placeholder={`${count} registros...`}
      />
    </div>
  );
}

export default function TableCustom({ columns, data }) {
  const {
    getTableProps,
    getTableBodyProps,
    headerGroups,
    prepareRow,
    page,
    state: { pageIndex, globalFilter },
    preGlobalFilteredRows,
    setGlobalFilter,
    pageOptions,
    pageCount,
    gotoPage,
    nextPage,
    previousPage,
    canPreviousPage,
    canNextPage,
  } = useTable(
    {
      columns,
      data,
      initialState: {
        pageSize: 10,
      },
    },
    useGlobalFilter,
    usePagination
  );

  return (
    <>
      <GlobalFilter
        preGlobalFilteredRows={preGlobalFilteredRows}
        globalFilter={globalFilter}
        setGlobalFilter={setGlobalFilter}
      />
      <div className="min-h-[calc(100vh-400px)]">
        <table {...getTableProps()} className="w-full table-fixed">
          <thead>
            {
              // Loop over the header rows
              headerGroups.map((headerGroup) => (
                // Apply the header row props
                <tr {...headerGroup.getHeaderGroupProps()}>
                  {
                    // Loop over the headers in each row
                    headerGroup.headers.map((column) => (
                      // Apply the header cell props
                      <th
                        {...column.getHeaderProps()}
                        className="pb-2 text-left uppercase text-gray-400"
                      >
                        {
                          // Render the header
                          column.render("Header")
                        }
                      </th>
                    ))
                  }
                </tr>
              ))
            }
          </thead>
          {/* Apply the table body props */}
          <tbody {...getTableBodyProps()}>
            {
              // Loop over the table rows
              page.map((row) => {
                // Prepare the row for display
                prepareRow(row);
                return (
                  // Apply the row props
                  <tr
                    {...row.getRowProps()}
                    className="border-t border-gray-200"
                  >
                    {
                      // Loop over the rows cells
                      row.cells.map((cell) => {
                        // Apply the cell props
                        return (
                          <td {...cell.getCellProps()} className="py-2">
                            {
                              // Render the cell contents
                              cell.render("Cell")
                            }
                          </td>
                        );
                      })
                    }
                  </tr>
                );
              })
            }
          </tbody>
        </table>
      </div>

      <div className="my-4 mx-4 flex flex-col items-center justify-center">
        <div className="">
          <ButtonNavigation
            onClick={() => gotoPage(0)}
            disabled={!canPreviousPage}
          >
            <FastArrowLeft className="text-white" />
          </ButtonNavigation>
          <ButtonNavigation
            onClick={() => previousPage()}
            disabled={!canPreviousPage}
          >
            <NavArrowLeft className="text-white" />
          </ButtonNavigation>
          <ButtonNavigation onClick={() => nextPage()} disabled={!canNextPage}>
            <NavArrowRight className="text-white" />
          </ButtonNavigation>
          <ButtonNavigation
            onClick={() => gotoPage(pageCount - 1)}
            disabled={!canNextPage}
          >
            <FastArrowRight className="text-white" />
          </ButtonNavigation>
        </div>

        <div className="mt-2 text-blue-400">
          Páginas{" "}
          <strong>
            {pageIndex + 1} de {pageOptions.length}
          </strong>{" "}
        </div>
      </div>
    </>
  );
}
