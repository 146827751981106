/* const url = "http://localhost:8000/api/"; */
const url = "https://ws-mantenimiento.corebitsdev.com/api/";

export const login = async (user) => {
  const response = await fetch(url + "users/login", {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
    },
    body: JSON.stringify(user),
  });
  return await response.json();
};

export const postUser = async (user) => {
  const response = await fetch(url + "users", {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
    },
    body: JSON.stringify(user),
  });
  return await response.json();
};

export const getUsers = async () => {
  const response = await fetch(url + "users");
  return await response.json();
};

export const getUser = async (id) => {
  const response = await fetch(url + "users/" + id);
  return await response.json();
};

export const getUserByCI = async (ci) => {
  const response = await fetch(url + "users/ci/" + ci);
  return await response.json();
};

export const putUser = async (user) => {
  const response = await fetch(url + "users/" + user.id, {
    method: "PUT",
    headers: {
      "Content-Type": "application/json",
    },
    body: JSON.stringify(user),
  });
  return await response.json();
};

export const deleteUser = async (id) => {
  const response = await fetch(url + "users/" + id, {
    method: "DELETE",
  });
  return await response.json();
};

export const getRoles = async () => {
  const response = await fetch(url + "roles");
  return await response.json();
};

export const postMaterial = async (material) => {
  const response = await fetch(url + "materials", {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
    },
    body: JSON.stringify(material),
  });
  return await response.json();
};

export const getMaterials = async () => {
  const response = await fetch(url + "materials");
  return await response.json();
};

export const getMaterial = async (id) => {
  const response = await fetch(url + "materials/" + id);
  return await response.json();
};

export const putMaterial = async (material) => {
  const response = await fetch(url + "materials/" + material.id, {
    method: "PUT",
    headers: {
      "Content-Type": "application/json",
    },
    body: JSON.stringify(material),
  });
  return await response.json();
};

export const deleteMaterial = async (id) => {
  const response = await fetch(url + "materials/" + id, {
    method: "DELETE",
  });
  return await response.json();
};

export const postEquipment = async (equipment) => {
  const response = await fetch(url + "equipments", {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
    },
    body: JSON.stringify(equipment),
  });
  return await response.json();
};

export const getEquipments = async () => {
  const response = await fetch(url + "equipments");
  return await response.json();
};

export const getEquipment = async (id) => {
  const response = await fetch(url + "equipments/" + id);
  return await response.json();
};

export const putEquipment = async (equipment) => {
  const response = await fetch(url + "equipments/" + equipment.id, {
    method: "PUT",
    headers: {
      "Content-Type": "application/json",
    },
    body: JSON.stringify(equipment),
  });
  return await response.json();
};

export const deleteEquipment = async (id) => {
  const response = await fetch(url + "equipments/" + id, {
    method: "DELETE",
  });
  return await response.json();
};

export const getEquipmentBySerie = async (equipment) => {
  const response = await fetch(url + "equipments/serie", {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
    },
    body: JSON.stringify(equipment),
  });
  return await response.json();
};

export const postService = async (service) => {
  const response = await fetch(url + "services", {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
    },
    body: JSON.stringify(service),
  });
  return await response.json();
};

export const getServices = async () => {
  const response = await fetch(url + "services");
  return await response.json();
};

export const getService = async (id) => {
  const response = await fetch(url + "services/" + id);
  return await response.json();
};

export const putService = async (service) => {
  const response = await fetch(url + "services/" + service.id, {
    method: "PUT",
    headers: {
      "Content-Type": "application/json",
    },
    body: JSON.stringify(service),
  });
  return await response.json();
};

export const deleteService = async (id) => {
  const response = await fetch(url + "services/" + id, {
    method: "DELETE",
  });
  return await response.json();
};
