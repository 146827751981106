import React from "react";

export default function ButtonNavigation({ onClick, disabled, children }) {
  return (
    <button
      className="mx-1 h-10 w-10 rounded-full bg-blue-400 p-2 font-bold text-white hover:bg-blue-500"
      onClick={onClick}
      disabled={disabled}
    >
      {children}
    </button>
  );
}
