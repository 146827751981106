import React from "react";
import { Link, Outlet, useNavigate } from "react-router-dom";
import { UserCircleAlt } from "iconoir-react";

import { AppContext } from "../../context/AppContext";
import Navbar from "../../components/Navbar";

export default function Admin() {
  const [appState, setAppState] = React.useContext(AppContext);
  const navigate = useNavigate();

  const closeSession = async () => {
    navigate("/");
    setAppState.logout();
  };

  return (
    <div className="flex min-h-screen bg-gradient-to-t from-gray-300 to-blue-500 py-6">
      <Navbar closeSession={closeSession} />
      <div className="mx-5 w-5/6 rounded-2xl">
        <div className="flex h-20 flex-row items-center justify-between px-4">
          <h2 className="text-lg font-semibold text-white">
            Panel de administración
          </h2>
          <Link
            to={`/profile/${appState.user.id}`}
            className="flex flex-row items-center px-2 py-1 hover:rounded hover:shadow-lg"
          >
            <UserCircleAlt className="text-white" />
            <span className="mx-3 ml-2 text-lg font-semibold text-white">
              {appState.user.name} ({appState.user.roles[0]?.name})
            </span>
            {/* <Settings className="text-white" /> */}
          </Link>
        </div>
        <div className="min-h-[calc(100vh-128px)] rounded-2xl border bg-white px-6 py-4 shadow-xl">
          <Outlet />
        </div>
      </div>
    </div>
  );
}
