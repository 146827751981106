import React from "react";
import { AppContext } from "../../context/AppContext";

import Swal from "sweetalert2";
import { login } from "../../services/ws";

export default function Login() {
  const [appState, setAppState] = React.useContext(AppContext);

  const [user, setUser] = React.useState({
    ci: "0123456789",
    password: "12345678",
  });

  const handleChange = (e) => {
    setUser({ ...user, [e.target.name]: e.target.value });
  };

  const handleSubmit = async (e) => {
    e.preventDefault();

    try {
      const resp = await login(user);

      if (resp.success) {
        setAppState.login(resp.data);
      } else
        Swal.fire({
          title: "Validación",
          text: resp.message,
          icon: "info",
        });
    } catch (error) {
      Swal.fire({
        icon: "error",
        title: "Oops...",
        text: "Algo salió mal!",
      });
    }
  };

  return (
    <div className="flex h-screen bg-gradient-to-t from-gray-300 to-blue-500">
      <div className="m-auto w-full max-w-md  rounded-lg border bg-white py-10 px-16 shadow-xl">
        <h1 className="mt-4 mb-8 text-center text-2xl font-medium uppercase">
          iniciar sesión
        </h1>

        <form onSubmit={handleSubmit}>
          <div>
            <label htmlFor="ci">Cédula</label>
            <input
              value={user.ci}
              onChange={handleChange}
              name="ci"
              type="text"
              className={`mb-4 w-full rounded-md border p-2 text-sm outline-none transition duration-150 ease-in-out`}
              placeholder="Ingrese su correo"
              minLength="10"
              maxLength="10"
              required
            />
          </div>
          <div>
            <label htmlFor="password">Contraseña</label>
            <input
              value={user.password}
              onChange={handleChange}
              name="password"
              type="password"
              className={`mb-4 w-full rounded-md border p-2 text-sm outline-none transition duration-150 ease-in-out`}
              placeholder="Ingrese su contraseña"
              minLength={8}
              required
            />
          </div>

          <div className="mt-6 flex items-center justify-center">
            <button
              className={`rounded border bg-blue-600 py-2 px-6 text-lg text-white`}
            >
              Ingresar
            </button>
          </div>
        </form>
      </div>
    </div>
  );
}
