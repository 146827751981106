import React from "react";
import LinkCustom from "./LinkCustom";

export default function ContentHeader({ title, text, to, children }) {
  return (
    <div className="flex items-center justify-between px-4 py-2">
      <h1 className="text-2xl font-bold text-gray-500">{title}</h1>

      <div className="">
        {to ? <LinkCustom text={text ? text : "Agregar"} to={to} /> : children}
      </div>
    </div>
  );
}
