import * as React from "react";

export const AppContext = React.createContext({});

export const AppContextProvider = ({ children }) => {
  const [appState, dispatch] = React.useReducer(
    (state, action) => {
      switch (action.type) {
        case "restore_login":
          return {
            ...state,
            user: action.user,
            isLoading: false,
          };
        case "login":
          return {
            ...state,
            user: action.user,
            isLoading: false,
          };
        case "logout":
          return {
            ...state,
            user: null,
            isLoading: false,
          };
        case "set_loading":
          return {
            ...state,
            isLoading: action.isLoading,
          };
        case "set_user":
          return {
            ...state,
            user: action.user,
          };
        default:
          return state;
      }
    },
    {
      user: null,
      isLoading: true,
    }
  );

  React.useEffect(() => {
    const user = localStorage.getItem("user");
    if (user) {
      dispatch({ type: "restore_login", user: JSON.parse(user) });
    }
  }, []);

  const setAppState = React.useMemo(
    () => ({
      login: async (user) => {
        dispatch({ type: "login", user });
        localStorage.setItem("user", JSON.stringify(user));
      },
      logout: () => {
        dispatch({ type: "logout" });
        localStorage.removeItem("user");
      },
      setLoading: async (isLoading) => {
        dispatch({ type: "set_loading", isLoading });
      },
      setUser: async (user) => {
        dispatch({ type: "set_user", user });
      },
    }),
    []
  );

  return (
    <AppContext.Provider value={[appState, setAppState]}>
      {children}
    </AppContext.Provider>
  );
};
