import * as React from "react";
import { Link, useMatch, useResolvedPath } from "react-router-dom";

export function ButtonNav({ text, children, to, ...props }) {
  const resolved = useResolvedPath(to);
  const match = useMatch({ path: resolved.pathname, end: true });

  return match ? (
    <div className="my-1 flex cursor-pointer flex-row items-center rounded-xl px-4 py-2 shadow-lg">
      <div className="rounded-lg bg-blue-500 p-1 text-xl text-white">
        {children}
      </div>
      <span className="pl-3 font-bold text-blue-900">{text}</span>
    </div>
  ) : (
    <Link
      className="my-1 flex cursor-pointer flex-row items-center rounded-xl px-4 py-2 hover:shadow-lg"
      to={to}
      {...props}
    >
      <div className="rounded-lg p-1 text-xl text-blue-500">{children}</div>
      <span className="pl-3 font-bold text-gray-400">{text}</span>
    </Link>
  );
}

export function ButtonNavLogout({ text, children, ...props }) {
  return (
    <div
      className="my-1 flex cursor-pointer flex-row items-center rounded-xl px-4 py-2 hover:shadow-lg"
      {...props}
    >
      <div className="rounded-lg p-1 text-xl text-blue-500">{children}</div>
      <span className="pl-3 font-bold text-gray-400">{text}</span>
    </div>
  );
}
