import React from "react";

export const ContainerInput = ({ children }) => {
  return <div className="mb-3 flex flex-wrap">{children}</div>;
};

export const InputCustom = ({
  text,
  name,
  value,
  type = "text",
  n = "1",
  ...props
}) => {
  return (
    <div className={"w-full px-3 " + (n === "2" ? " md:w-1/2" : "")}>
      <label className="mb-2 block text-xs font-bold uppercase tracking-wide text-gray-700">
        {text}
      </label>
      <input
        name={name}
        value={value || ""}
        className="block w-full appearance-none rounded border border-gray-200 bg-gray-200 py-2 px-4 text-sm leading-tight text-gray-700 focus:border-gray-500 focus:bg-white focus:outline-none"
        type={type}
        placeholder={text}
        {...props}
      />
    </div>
  );
};

export const SelectCustom = ({
  text,
  name,
  value,
  options,
  n = "1",
  lst,
  ...props
}) => {
  return (
    <div className={"w-full px-3 " + (n === "2" ? " md:w-1/2" : "")}>
      <label className="mb-2 block text-xs font-bold uppercase tracking-wide text-gray-700">
        {text}
      </label>
      <select
        name={name}
        value={value || ""}
        className="block w-full appearance-none rounded border border-gray-200 bg-gray-200 py-2 px-4 pr-8 text-sm leading-tight text-gray-700 focus:border-gray-500 focus:bg-white focus:outline-none"
        {...props}
      >
        <option value="">Seleccione</option>
        {lst.map((item) => (
          <option key={item.id} value={item.id}>
            {item.name + (item.last_name ? " " + item.last_name : "")}
          </option>
        ))}
      </select>
    </div>
  );
};
