import React from "react";
import { Link } from "react-router-dom";
import { Home, User, LogOut, Box } from "iconoir-react";
import { ButtonNav, ButtonNavLogout } from "./ButtonNav";

import logo from "../assets/images/logo.png";
import { AppContext } from "../context/AppContext";

export default function Navbar({ closeSession }) {
  const [appState] = React.useContext(AppContext);

  return (
    <div className="ml-5 max-h-screen w-1/6 rounded-2xl border bg-white py-8 px-6 shadow-xl">
      <div className="mb-6 border-b px-8 pb-6">
        <Link to="/" className="">
          <img src={logo} alt="logo" />
        </Link>
      </div>

      <ButtonNav text="Panel Principal" to="/">
        <Home />
      </ButtonNav>

      <ButtonNav text="Usuarios" to="/users">
        <User />
      </ButtonNav>

      <ButtonNav text="Materiales e Insumos" to="/materials">
        <Box />
      </ButtonNav>

      <ButtonNav text="Equipos" to="/equipments">
        <Box />
      </ButtonNav>

      <ButtonNav text="Servicios" to="/services">
        <Box />
      </ButtonNav>

      <ButtonNav text="Orden de Reparación" to="/repairs">
        <Box />
      </ButtonNav>

      <div className="mx-3 mt-4 mb-3">
        <span className="text-xl font-bold text-blue-900">Opciones</span>
      </div>

      <ButtonNav text="Perfil" to={`/profile/${appState.user.id}`}>
        <User />
      </ButtonNav>
      <ButtonNavLogout text="Cerrar Sesión" onClick={closeSession}>
        <LogOut />
      </ButtonNavLogout>
    </div>
  );
}
