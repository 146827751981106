import React from "react";
import TableCustom from "../../../components/TableCustom";
import { getMaterials, deleteMaterial } from "../../../services/ws";

import Swal from "sweetalert2";
import ContentHeader from "../../../components/ContentHeader";
import LinkCustom from "../../../components/LinkCustom";
import ButtonCustom from "../../../components/ButtonCustom";
import Loading from "../../../components/Loading";

import { lstTypeMaterials, lstBrands } from "../../../utils/LstData";

export default function MaterialsList() {
  const columns = [
    {
      Header: "ID",
      accessor: "id",
    },
    {
      Header: "Serie",
      accessor: "serie",
    },
    {
      Header: "Marca",
      accessor: "brand",
    },
    {
      Header: "Modelo",
      accessor: "model",
    },
    {
      Header: "Descripción",
      accessor: "description",
    },
    {
      Header: "Tipo",
      accessor: "type",
    },
    {
      Header: "Stock",
      accessor: "stock",
    },
    {
      Header: "Precio",
      accessor: "price",
    },
    {
      Header: "Opciones",
      accessor: (props) => (
        <>
          <LinkCustom
            text="Editar"
            to={`/materials/${props.id}`}
            className="text-sm"
          />
          <ButtonCustom
            text="Eliminar"
            onClick={() => handleDelete(props.id)}
            className="text-sm"
            color="danger"
          />
        </>
      ),
    },
  ];

  const [isLoading, setIsLoading] = React.useState(true);

  const [data, setData] = React.useState([]);

  React.useEffect(() => {
    handleGet();
  }, []);

  const handleGet = async () => {
    const resp = await getMaterials();
    if (resp.success) {
      const dataProcessed = resp.data.map((item) => {
        return {
          ...item,
          type: lstTypeMaterials.find((type) => type.id == item.type).name,
          brand: lstBrands.find((brand) => brand.id == item.brand).name,
        };
      });
      setData(dataProcessed);
    }
    setIsLoading(false);
  };

  const handleDelete = async (id) => {
    Swal.fire({
      title: "¿Estás seguro?",
      text: "Una vez eliminado, no podrás recuperar este registro!",
      icon: "warning",
      showCancelButton: true,
      confirmButtonColor: "#3085d6",
      cancelButtonColor: "#d33",
      confirmButtonText: "Si, eliminar!",
    }).then(async (result) => {
      if (result.value) {
        const resp = await deleteMaterial(id);
        if (resp.success) {
          Swal.fire("Eliminado!", resp.message, "success");
          handleGet();
        }
      }
    });
  };

  if (isLoading) {
    return <Loading />;
  }

  return (
    <>
      <ContentHeader
        to={`/materials/new`}
        title="Listado de Materiales e Insumos"
      />
      <TableCustom columns={columns} data={data} />
    </>
  );
}
