import React from "react";
import { BrowserRouter, Routes, Route } from "react-router-dom";

import Home from "./pages/admin/Home";
import Login from "./pages/auth/Login";
import Admin from "./pages/admin/Admin";
import Welcome from "./pages/admin/Welcome";
import ProductsList from "./pages/admin/products/ProductsList";
import ProductForm from "./pages/admin/products/ProductForm";
import UsersList from "./pages/admin/users/UsersList";
import UserForm from "./pages/admin/users/UserForm";

import { AppContext } from "./context/AppContext";

import MaterialsList from "./pages/admin/materials/MaterialsList";
import MaterialForm from "./pages/admin/materials/MaterialForm";
import EquipmentsList from "./pages/admin/equipments/EquipmentsList";
import EquipmentForm from "./pages/admin/equipments/EquipmentForm";
import ServicesList from "./pages/admin/servicesTech/ServicesList";
import ServiceForm from "./pages/admin/servicesTech/ServiceForm";
import RepairsList from "./pages/admin/repair/RepairsList";
import RepairForm from "./pages/admin/repair/RepairForm";

export default function RootRouter() {
  const [appState] = React.useContext(AppContext);
  console.log("appState", appState);

  return (
    <BrowserRouter>
      <Routes>
        {appState.user ? (
          <Route path="/" element={<Admin />}>
            <Route path="/" element={<Home />} />
            <Route path="users" element={<UsersList />} />
            <Route path="users/new" element={<UserForm />} />
            <Route path="users/:id" element={<UserForm />} />
            <Route path="profile/:id" element={<UserForm />} />
            <Route path="materials" element={<MaterialsList />} />
            <Route path="materials/new" element={<MaterialForm />} />
            <Route path="materials/:id" element={<MaterialForm />} />
            <Route path="equipments" element={<EquipmentsList />} />
            <Route path="equipments/new" element={<EquipmentForm />} />
            <Route path="equipments/:id" element={<EquipmentForm />} />
            <Route path="services" element={<ServicesList />} />
            <Route path="services/new" element={<ServiceForm />} />
            <Route path="services/:id" element={<ServiceForm />} />
            <Route path="repairs" element={<RepairsList />} />
            <Route path="repairs/new" element={<RepairForm />} />
            <Route path="repairs/:id" element={<RepairForm />} />

            {/* <Route path="products" element={<ProductsList />} />
          <Route path="products/new" element={<ProductForm />} />
          <Route path="products/:id" element={<ProductForm />} /> */}
          </Route>
        ) : (
          <Route path="/" element={<Login />} />
        )}
      </Routes>
    </BrowserRouter>
  );
}
